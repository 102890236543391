.App {
  text-align: center;
  height: calc(4*50vh);
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}



.backGround{
  width: 100%;
  height: 50vh;
  position: absolute;
}

.aqua{
  background-color: aqua;
}

.red{
  background-color: red;
}
.green{
  background-color: green;
}
.purple{
  background-color: purple;
}
.z1{
  z-index: 1;
}
.z2{
  z-index: 2;
}
.z3{
  z-index: 3;
}
.z4{
  z-index: 4;
}
.full-height{
  height: calc(4*50vh);
}
.full-size{
  height: 100vh;
}
.fix{
  position:fixed;
  top : 0;
}

.translucid{
  opacity: 0.5;
}
.transparent{
  opacity: 0.1;
}